.quiz-container {
    margin: 50px auto;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
    }
  
    .timer {
      font-size: 16px;
      color: red;
    }
  
    .test-form {
      border: 1px solid #E1E1E1;
      padding: 20px;
      border-radius: 12px;
  
      .form-label {
        font-weight: bold;
        margin-bottom: 20px;
        padding-left: 0px !important;
      }
  
      .custom-radio {
        display: flex;
        align-items: center;
        margin-bottom: 10px; 
  
        input[type="radio"] {
          margin-right: 10px; 
          cursor: pointer;
        
        }
  
        label {
          margin-bottom: 0; 
        }
      }
    }
    .attempt-count{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .resultContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
    
    .resultCard {
      background: #fff;
      border-radius: 12px;
      padding: 40px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: relative;
    
      h2 {
        font-size: 24px;
        margin-bottom: 10px;
      }
    
      p {
        font-size: 16px;
        margin: 5px 0;
      }
    
      .result-status {
        color: green;
        font-size: 18px;
        margin-bottom: 20px;
      }
    
      .result-score {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    
      h1 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 30px;
      }
    
      .confetti {
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        background-image: url('../../assets/result.png'); 
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
      }
    }
    
  }
  .quizLoading{
    display: flex;
    align-items: center; 
    flex-direction: column;
    justify-content: center;
    height: 80dvh;
    p{
      margin-top: 30px;
    }
  }
  