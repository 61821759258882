.contact-us {
  position: relative; 
  margin-bottom: 200px;
  .contactBanner {
    background-image: url("../../assets/contact.png");
    background-size: cover;
    background-position: center;
    padding: 50px;
    position: relative;
    .heading {
      color: white;
    }
    .text {
      color: white;
      width: 43%;
    }
  }
  .left-side {
    h4 {
      margin-top: 50px;
    }
    p {
      font-size: 18px;
      line-height: 1.5;
    }
  }
  .right-side {
    padding: 20px;
    background: white;
    position: absolute;
    right: 0; 
    left: 75%; 
    top: 100px;
    transform: translateX(-50%);
    max-width: 500px; 
    width: 100%; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    .ant-form-item {
      margin-bottom: 16px;
    }
    .submit-btn {
      background-color: #3d3c6e;
      border-radius: 25px;
      width: 100%;
      height: 42px;
      font-size: 18px;
    }
    .submit-btn:hover{
      background-color: #3d3c6e !important;
      color: white !important;
      border: none !important;
    }
  }
}
