.profile-container {
    margin-top: 50px;
  
    .profile-content {
      background: #fff;
      margin: 0;
      min-height: 280px;
      .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  
    h1 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    .ant-tabs {
      .ant-tabs-nav {
        margin: 0;
      }
  
      .ant-tabs-tab {
        font-size: 16px;
  
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #ED1C24;
          }
        }
      }
  
      .ant-tabs-ink-bar {
        background: #ED1C24;
      }
      .ant-tabs-tabpane {
        margin-top: 30px;
      }
    }
    .shareData{
      background-color: red;
      color: white;
      border: none;
      width:120px ;
      padding: 10px;
      border-radius: 25px;
      font-weight: bold;
    }
  }
  