.forum-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 50px;
  
    .forum-content {
      // padding: 24px;
      // max-width: 800px;
      width: 100%;
     
    }
  
    h1 {
      font-size: 24px;
      margin-bottom: 24px;
    }
  
    .question-form {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

  
      .question-avatar {
        margin-right: 16px;
      }
  
      .question-input {
        flex: 1;
        margin-right: 16px;
        border-radius: 8px;
      }
  
      .ask-question-button {
        border-radius: 8px;
      }
    }
  
    .forum-item {
      background: #fff;
      padding: 24px;
      margin-bottom: 16px;
      border: 1px solid #e8e8e8;
      border-radius: 8px;
  
      .forum-item-meta {
        display: flex;
  
        .forum-item-info {
          margin-left: 16px;
          flex: 1;
  
          h2 {
            font-size: 18px;
            margin-bottom: 8px;
          }
  
          p {
            margin-bottom: 12px;
            color: rgba(0, 0, 0, 0.65);
          }
  
          .forum-item-content {
            margin-bottom: 16px;
          }
  
          .forum-item-actions {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
  