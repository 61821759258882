.dashboard-course{
    margin-top: 50px;
    margin-bottom: 50px;
    .ant-tabs {
        .ant-tabs-nav {
          margin: 0;
        }
    
        .ant-tabs-tab {
          font-size: 16px;
    
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #ED1C24;
            }
          }
        }
    
        .ant-tabs-ink-bar {
          background: #ED1C24;
        }
        .ant-tabs-tabpane {
          margin-top: 30px;
        }
      }
}
.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}