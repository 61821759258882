.signup {
  width: 100%;
  .row {
    margin-left: -8px;
    margin-right: 0px;
  }
  .heading {
    font-weight: 700;
    margin-top: 30px;
  }
  .input_custom {
    // border: 1px solid #dedee7;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px;
    // padding: 8px;

    &.ant-radio-wrapper-checked {
      border-color: #ed1c24;
    }
  }

  .react-tel-input .form-control {
    width: 100%;
    border: 1px solid #dedee7;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px;
    padding: 8px;
    height: auto;
  }

  .react-tel-input .flag-dropdown {
    border-radius: 25px 0 0 25px;
    border: 1px solid #dedee7;
    height: 87%;
  }

  .react-tel-input .flag-dropdown .selected-flag {
    padding: 8px 12px;
  }

  .react-tel-input .form-control:focus {
    border-color: #dedee7;
    box-shadow: none;
  }

  .react-tel-input .form-control {
    padding-left: 52px; /* Adjust padding to fit the flag inside the input */
  }

  .react-tel-input .flag {
    border: none;
  }

  :where(.css-dev-only-do-not-override-j9bb5n).ant-form-item {
    margin-bottom: 15px !important;
  }

  .ant-radio-group {
    width: 100%;
    display: flex;
  }

  .ant-radio-wrapper {
    width: 50%;
    position: relative;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: black !important;
    border: none !important;
  }

  .ant-radio-wrapper .ant-radio-inner {
    border: 2px solid black;
  }

  .ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    padding-right: 10px;
  }

  .Radio_btn {
    margin: 20px 0px;
  }

  .leftSide {
    width: 100%;
    .loginImg {
      width: 100%;
      height: 117vh;
      object-fit: cover;
    }
  }

  .column2 {
    display: flex;
    justify-content: center;
    .rightSide {
      width: 80%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .logo {
        margin: 20px 0px;
      }
      .para {
        width: 60%;
        font-size: larger;
        color: #424259;
        opacity: 0.5;
      }
      .form {
        width: 100%;
        margin-bottom: 30px;
      }
      .forgot_pass {
        margin: 5px 0px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .bottom_text {
      text-align: center !important;

      // position: absolute !important;
      width: 100% !important;
      font-size: 16px;
      span {
        font-weight: 600;
      }
    }

    .signinBtn {
      background-color: #3d3c6e;
      border-radius: 25px;
    }

    .googleBtn {
      background-color: white;
      color: black;
      width: 100%;
      border-radius: 25px;
      font-weight: 500;
      padding: 5px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    .leftSide {
      display: none;
    }

    .rightSide {
      width: 100%;
      padding: 0px !important;
    }

    .para {
      width: 100% !important;
    }

    .forgot_pass {
      margin-bottom: 35px !important;
    }
  }
}
