.certificateList {
  width: 200px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .certificateContent {
    h5 {
      color: black;
      text-decoration: none;
    }
.link{
    color: black;
    text-decoration: none;
}
.link:hover{
    text-decoration: none;
}
  
  }
}
