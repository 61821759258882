.cards {
  display: flex;
  // justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  .card {
    width: 250px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      height: 200px;
      width: 100%;
    }
  }
  .textlink {
    color: black;
    text-decoration: none;
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .title {
    font-weight: 700;
    margin: 10px 0 0 !important;
    display: inline-block;
    width: 220px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .price {
    font-weight: 700;
    margin: 0px 0 0 !important;
    display: inline-block;
    width: 220px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .courseProgress {
    p {
      margin-bottom: 0px;
    }
  }
  .button {
    background-color: white;
    border: 1px solid #ed1c24;
    color: #ed1c24;
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .button:hover {
    background-color: #ed1c24 !important;
    color: white !important;
  }
 
  @media (max-width: 767px) {
    flex-direction: column !important;
    .card {
      width: 100% !important;
    }
  }
}
.no-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img{
    width: 600px;
  }
  p{
    margin-top: 30px;
  }
}
