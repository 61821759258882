.navbar{
  .navbar-brand img {
    width: 200px;
  }
  
  .navbar-nav .nav-link {
    color: black;
    &:hover {
      color: red;
    }
  }
  
  .navbar-nav .nav-link.active {
    color: red !important;
  }
  
  .navbar-nav .nav-link .fa-shopping-cart {
    font-size: 1.25rem;
  }
  
  .navbar-nav .nav-dropdown {
    .dropdown-menu {
      .dropdown-item {
        &:hover {
          background-color: #f8f9fa;
        }
      }
    }
  }
}

