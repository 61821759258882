.square-payment-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    // text-align: center;
    #rswps-form{
        width: 100% !important;
    }
  }
  .paymentLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
        margin-top: 30px;
        font-size: large;
    }
    
  }
  