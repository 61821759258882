.course-details {
  .course-container {
    margin-bottom: 50px;
    .course-header {
      background-color: #f5f5f7;
      padding-top: 30px;
      padding-bottom: 30px;
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 2rem;
      }
      .course-description {
        max-width: 65%;
      }

      .course-image {
        width: 30%;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }

    .tests-included {
      margin-top: 50px;
      .included-container {
        .test-cards {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .cards {
            display: flex;
            gap: 20px;
          }
          .test-card {
            background-color: #fff;
            border-radius: 8px;
            overflow: hidden;
            padding: 10px;
            .ant-card-body {
              padding: 10px 0px !important;
              
            }
            img {
              width: 250px;
              height: 230px;
            }
          
          }
          
        }
        .amount-card {
          width: 250px;
          background-color: #3d3c6e08;
          padding: 1rem;
          border-radius: 8px;

          .total-amount {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .add-review {
    margin-top: 30px;
    .textArea {
      margin: 20px auto;
    }
    .ratings{
      font-size: 35px;
    }
    .validation-message {
      color: red;
      opacity: 0.7;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
