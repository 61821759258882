.purchase-history {
  margin-bottom: 200px;
  .item-cell{
    img{
      width: 50px;
      height: 50px;
      border-radius: 12px;
    }
    span{
      margin-left: 10px;
    }
  }
    .ant-table {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
  
      .ant-table-container {
        border: none;
      }
  
      .ant-table-thead > tr > th {
        background-color: #fafafa;
        font-weight: bold;
      }
  
      .ant-table-tbody > tr > td {
        padding: 16px;
      }
  
      .item-cell {
        display: flex;
        align-items: center;
  
        .ant-avatar {
          margin-right: 8px;
        }
      }
    }
  }
  