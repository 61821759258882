.ForgotPassword {
  height: 100vh;
  overflow: hidden;

  .row {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0; // Remove any padding that might be causing lines

    .leftSide {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .loginImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .imageOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .column2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img{
        margin-bottom: 10px;
      }
    }

    .rightSide {
      width: 80%;

      h3 {
        margin-bottom: 20px 0px 20px;
        color: #333333;
        font-size: 24px;
      }
      .input_custom {
        border: 1px solid #dedee7;
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 25px;
      }

  
    }
  }
}

@media (max-width: 767px) {
  .ForgotPassword {
    .row {
      .leftSide {
        display: none;
      }
      .column2 {
        padding: 20px;
        overflow: auto;
      }
    }
  }
}
