/* ShoppingCart.scss */
.shopping-cart {
  margin-top: 50px;
  .ant-card {
    border-radius: 8px;
  }
  .cart-summary {
    p {
      font-weight: 500;
    }
  }
  .course-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
    .cart-image {
      width: 50px;
      height: 50px;
      border-radius: 12px;
    }
  }

  .course-item:last-child {
    border-bottom: none;
  }

  .course-details {
    flex-grow: 1;
    margin-left: 20px;
  }

  .course-details h3 {
    margin: 0;
    font-size: 16px;
  }

  .course-details p {
    margin: 0;
    color: #888;
  }

  .course-actions {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }

  .course-price {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .total-amount-card {
    // margin-top: 20px;
    // text-align: center;
    background-color: #efefef;
  }

  .total-amount-card h4 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #888;
  }

  .total-amount-card h3 {
    margin-top: 0;
    font-size: 24px;
  }
  .checkoutBtn {
    width: 100%;
    background-color: #ed1c24;
    color: white;
    border-radius: 25px;
  }
  .checkoutBtn:hover {
    // background-color: #ED1C24;
    border: 2px solid #ed1c24 !important;
    color: #ed1c24 !important;
  }
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  .payment-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
  }

  .cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #ff4d4f; 
  }

  .cancel-button:hover {
    color: #d43f3a;
  }
}
