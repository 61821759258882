.invoices {
  margin-bottom: 200px;
    .ant-table {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
  
      .ant-table-container {
        border: none;
      }
  
      .ant-table-thead > tr > th {
        background-color: #fafafa;
        font-weight: bold;
      }
  
      .ant-table-tbody > tr > td {
        padding: 16px;
      }
    }
  }
  