// src/TestScreen.scss
.test-screen-container {
    margin-top: 2rem;
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2{
      margin-bottom: 20px;
    }
  }

    .timer {
      color: red;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .form-group {
      margin-bottom: 1rem;
    }
  
    .form-label {
      font-weight: bold;
    }
  
    .form-check {
      margin-bottom: 0.5rem;
    }
  
    .submit-button {
      display: flex;
      align-items: center;
  
      svg {
        margin-left: 0.5rem;
      }
    }
  }
  