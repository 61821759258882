.spinner {
    width: 46px;
    height: 46px;
    display: grid;
    animation: spinner-plncf9 3s infinite;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: #474bff #474bff #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner-plncf9 1s infinite linear;
  }
  
  .spinner::after {
    border-color: #0000 #0000 #dbdcef #dbdcef;
    animation-direction: reverse;
  }
  
  @keyframes spinner-plncf9 {
    100% {
      transform: rotate(1turn);
    }
  }
  